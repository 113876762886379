import React, { Component } from "react"
import { TM } from "../../common/typography/trademark"
import * as styles from "./declineBountyContest.module.scss"

class declineBountyContest extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className={styles.carouselGraphic3}>
        <div className="row">
          <div className="offset-md-2 col-md-8">
            <h2>
              Decline to register for the IronCAP
              <TM /> Live Demo{" "}
            </h2>
            <p>
              Declining to register means you won’t have an account to login in
              order to try the IronCAP
              <TM /> encryption / decryption technology.
            </p>

            <p>
              <b>Why registration is required</b>
              <br />
              For security we make sure each demo account has a unique pair of
              private/public keys. This means data you have encrypted can only
              be decrypted by you in your IronCAP
              <TM /> Live Demo account. In other words, a user with a different
              IronCAP
              <TM /> Live Demo account will not be able to decrypt your data.
            </p>

            <p>
              <b>Register again</b>
              <br />
              If you change your mind, you are welcome to try the registration
              process again by accepting our rules and conditions.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="offset-md-2 col-md-2 btn-group">
            <button
              className="btn btn-agreement-accept"
              onClick={this.props.handleReturnToAgreement}
            >
              Go back{" "}
            </button>
          </div>

          <div className="col-md-2 btn-group">
            <button
              className="btn btn-agreement-cancel"
              onClick={this.props.handleReturnToWelcome}
            >
              Cancel signup{" "}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default declineBountyContest
