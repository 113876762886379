import React, { Component } from "react"
import { TM } from "../../common/typography/trademark"
import * as styles from "./agreementBountyContest.module.scss"

class AgreementBountyContest extends Component {
  constructor(props) {
    super(props)
    this.state = { activeCheckBox: false, enableCheckbox: false }
  }

  handleCheckboxClick = e => {
    this.setState({ activeCheckBox: e.target.checked })
  }

  handleScroll = e => {
    let element = e.target

    console.log(element.scrollHeight - element.scrollTop)
    console.log(element.clientHeight)

    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 20) {
      this.setState({ enableCheckbox: true })
    }
  }

  render() {
    return (
      <div className={styles.carouselGraphic3}>
        <div className="row">
          <div className="offset-md-2 col-md-8">
            <div className={styles.carouselText}>
              <h4>
                Before you register an IronCAP
                <TM /> Live Demo account
              </h4>
            </div>

            <p>
              By registering to the IronCAP
              <TM /> Live Demo, you agree to be bound by the IronCAP
              <TM /> Live Demo Agreement. The Company reserves the right in its
              sole discretion, to terminate any account that the Company
              believes is: (1) violating the Live Demo Agreement; (b) tampering
              or attempting to tamper with the registration process or the
              operation of the Live Demo, the Live Demo website, or any related
              promotional website; (c) violating the terms of service,
              conditions of use and/or general rules or guidelines of any of the
              Company's property or service; and/or (d) acting in an disruptive
              manner, or with intent to annoy, abuse, threaten or harass any
              other person.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="offset-md-2 col-md-8">
            <div
              id="rules"
              className={styles.rules}
              onScroll={this.handleScroll}
            >
              <div className={styles.heading}>
                <p>01 Communique</p>
                <p>
                  IronCAP
                  <TM /> Live Demo Agreement
                </p>
              </div>
              <p>
                These rules constitute a binding agreement between each User and
                01 Communique Laboratory Inc. (01 Communique). Please read them
                carefully. By agreeing to using this IronCAP
                <TM /> Live Demo (the “Demo”) hosted by 01 Communique, Users
                confirm they have read and understood these terms, conditions,
                privacy policy and agree to be bound and abide by them.
              </p>
              <ol tyle="1">
                <li>
                  {" "}
                  <b>Binding Agreement.</b> You agree that signing up an IronCAP
                  <TM /> Live Demo Account constitutes agreement to these terms
                  and conditions. These terms and conditions form a binding
                  legal agreement between you and 01 Communique with respect to
                  the Demo.
                  <br />
                  <br />
                </li>

                <li>
                  <b>Changes to this Agreement.</b> We reserve the right to
                  amend these terms at any time by posting a revised Agreement
                  on its website at{" "}
                  <a href="https://www.ironcap.ca/" target="_blank">
                    https://www.ironcap.ca/
                  </a>
                  . If you use the Demo after the date of the change, you agree
                  and consent to the change. You understand and agree that you
                  are solely responsible for periodically reviewing the terms of
                  the Agreement.
                  <br />
                  <br />
                </li>

                <li>
                  <b>Accessing the Demo.</b> We may suspend or cancel your
                  access to the IronCAP
                  <TM /> Live Demo without giving you notice if we believe that
                  you are (a) violating the IronCAP
                  <TM /> Live Demo Agreement; (b) tampering or attempting to
                  tamper with the operation of the Demo or the Company’s
                  websites; (c) using it inappropriately or if there is unusual
                  activity in or relating to your Live Demo Account; (d)
                  violating the terms of service, conditions of use and/ or
                  general rules or guidelines of any of the Company’s property
                  or service and/ or (e) acting with the intent to annoy, abuse,
                  threaten or harass any other person.
                  <br />
                  <br />
                </li>

                <li>
                  <b>Usage Requirements.</b> All usage must comply with all of
                  the following requirements (“Usage Requirements”):
                  <ol type="i">
                    <li>
                      You must have a Facebook or Goggle account in order to
                      register an account to use the IronCAP
                      <TM /> Live Demo.{" "}
                    </li>
                    <li>
                      Users that exploit bugs in 01 Communique’s software and
                      website, or otherwise violate the spirit of the Demo, will
                      be rejected.
                    </li>
                    <li>
                      Usage cannot be in violation of any law, regulation or
                      third party rights.{" "}
                    </li>
                  </ol>
                  <br />
                </li>

                <li>
                  <b>Intellectual Property.</b> Unless otherwise indicated, 01
                  Communique is the owner of all intellectual property rights
                  subsisting on each website you access through the IronCAP
                  <TM />
                  Live Demo. Unless otherwise indicated, trademarks and logos,
                  and all works including texts, images, illustrations,
                  software, HTML codes, audio clips and videos appearing on our
                  websites, demos or services are our property. Without our
                  express written permission, they may not be reproduced,
                  republished, downloaded, posted, transmitted, distributed or
                  modified, in whole or in part, in any form whatsoever, except
                  for personal and non-commercial use, including viewing,
                  printing or archiving of electronic copies of your online
                  activities, in accordance with the terms of this Agreement and
                  as we may further instruct you. Nothing in this Agreement or
                  on any of our Demo or Service is to be interpreted as
                  conferring a right to use our works, trademarks or logos on
                  any other way.
                  <br />
                  <br />
                </li>

                <li>
                  <b>Privacy.</b> Users agree that personal data submitted,
                  including without limitation name, mailing address, phone
                  number, and email address may be collected, processed, stored
                  and otherwise used by 01 Communique and its affiliates for the
                  purposes of conducting and administering the Demo. 01
                  Communique may also use your personal information to send you
                  updates and promotional materials from time to time. The
                  information collected is subject to 01 Communique’s privacy
                  policy located at{" "}
                  <a href="https://www.01com.com/privacy/" target="_blank">
                    https://www.01com.com/privacy/
                  </a>
                  . By registering to use the IronCAP
                  <TM /> Live Demo, you agree to 01 Communique's privacy policy,
                  as it may apply to the collection and use of your personal
                  information and acknowledge that you have read and accepted 01
                  Communique’s privacy policy.
                  <br />
                  <br />
                </li>

                <li>
                  <b>Disclaimer and Limitation of Liability. </b>

                  <ol type="a">
                    <li>
                      Access to the IronCAP
                      <TM /> Live Demo content and features is solely at the
                      risk of the users, including without limitation the risk
                      that the Demo content and features are inaccurate or
                      incomplete, or that the Demo content and features and the
                      transmission thereof may be corrupted or contain viruses,
                      bugs or other defects.
                      <br />
                      <br />
                    </li>

                    <li>
                      ANY ATTEMPT BY AN INDIVIDUAL TO DELIBERATELY DAMAGE ANY
                      WEBSITE ASSOCIATED WITH THIS IRONCAP
                      <TM /> LIVE DEMO OR TO UNDERMINE THE LEGITIMATE OPERATION
                      OF THIS DEMO MAY BE A VIOLATION OF CRIMINAL AND/OR CIVIL
                      LAWS. SHOULD ANY SUCH ATTEMPT BE MADE, 01 COMMUNIQUE
                      RESERVES THE RIGHT TO SEEK REMEDIES AND DAMAGES TO THE
                      FULLEST EXTENT PERMITTED BY LAW AND TO BAN OR DISQUALIFY
                      AN USER FROM THIS AND FUTURE SERVICE.
                      <br />
                      <br />
                    </li>

                    <li>
                      01 COMMUNIQUE MAKES NO WARRANTIES, CONDITIONS OR
                      REPRESENTATIONS, EXPRESS OR IMPLIED, IN FACT OR IN LAW,
                      INCLUDING ANY WARRANTY OF MERCHANTABILITY,
                      NON-INFRINGEMENT, OWNERSHIP, ACCURACY, COMPLETENESS, QUIET
                      ENJOYMENT, OR FITNESS FOR A PARTICULAR PURPOSE, AND 01
                      COMMUNIQUE DISCLAIMS ANY RESPONSIBILITY REGARDING THE
                      OPERATION OF ITS WEBSITES AND THE ACCURACY OF ANY AND ALL
                      CONTENT, OR THEIR SUITABILITY FOR THE PURPOSES OF THE
                      USER.
                      <br />
                      <br />
                    </li>

                    <li>
                      To the extent permitted by law, in no event shall 01
                      COMMUNIQUE and or its affiliates, suppliers of materials
                      or services, and all of their respective shareholders,
                      directors, officers, employees and agents (collectively,
                      the "Releasees") be liable for any indirect, special,
                      incidental, consequential or punitive damages, including
                      but not limited to those for business interruption or loss
                      of profits, even if the Releasees have been notified of
                      the possibility of such damage.
                      <br />
                      <br />
                    </li>

                    <li>
                      The Releasees do not assume any responsibility for, and by
                      registering a Demo account, users release the Releasees
                      from any and all claims, actions, damages, demands and
                      liabilities of whatever nature or kind arising out of, or
                      in connection with accessing or attempted access to the
                      Demo, including, without limitation the administration of
                      the Demo, the Account registration, or using any function
                      and feature provided in the Demo.
                      <br />
                      <br />
                    </li>

                    <li>
                      Without limiting the generality of the forgoing, the
                      Releasees are not responsible for:
                      <ol type="i">
                        <li>
                          the incorrect or inaccurate capture of a registration,
                          or any component thereof;
                          <br />
                          <br />
                        </li>

                        <li>
                          any loss, damage, or claims caused by the Demo itself,
                          or by the functions and operations provided; <br />
                          <br />
                        </li>

                        <li>
                          any failure of any website connected to the Demo,
                          including, without limitation, any problems, human or
                          technical, printing errors, lost, delayed, garbled
                          data or transmissions, omissions, interruptions,
                          deletions, defects or failures of any electrical
                          outlets, telephone or computer lines, technical
                          malfunctions of any computer online systems, servers,
                          access providers, computer equipment, software,
                          failure of any registration to be received on account
                          of technical or other problems, or any combination
                          thereof including, without limitation, any injury or
                          damage to an individual’s or any other person's
                          computer related to or resulting from using or
                          downloading any material connected to our websites;
                          <br />
                          <br />
                        </li>

                        <li>
                          any errors, omissions, incorrect or inaccurate
                          information in any Demo-related materials, including,
                          without limitation, printing or advertising errors or
                          failure of, or problems with any equipment or
                          programming associated with or used in the Demo
                          howsoever caused;
                          <br />
                          <br />
                        </li>

                        <li>
                          the security or privacy of information transmitted via
                          computer networks or for breaches of privacy due to
                          interference by third party computer "hackers" or
                          otherwise;
                          <br />
                          <br />
                        </li>
                      </ol>
                    </li>

                    <li>
                      01 COMMUNIQUE’s maximum liability arising from any and all
                      claims in connection with the IronCAP
                      <TM /> Live Demo shall not exceed $10.
                      <ol type="i">
                        <li>
                          Users shall indemnify and hold 01 COMMUNIQUE harmless
                          from any claims, demands, damages, liabilities and
                          costs (including legal fees) asserted by any third
                          party relating in any way to breach of the foregoing.
                          <br />
                          <br />
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li>
                  <b>GENERAL</b>

                  <ol type="a">
                    <li>
                      These terms contain the entire agreement between the
                      parties with respect to the subject matter hereof and
                      supersedes all prior agreements, negotiations,
                      representations and proposals, written or oral, relating
                      to the subject matter hereof.
                      <br />
                      <br />
                    </li>

                    <li>
                      This IronCAP
                      <TM /> Live Demo is void where prohibited by law and is
                      subject to all applicable federal, provincial and
                      municipal laws and regulations. 01 COMMUNIQUE reserves the
                      right to cancel, amend or suspend this Demo, or to amend
                      these terms, at any time, for any reason, and without
                      prior notice or obligation. Updated or amended versions
                      will be posted on ironcap.ca. Users are responsible for
                      checking ironcap.ca regularly for any updates to these
                      terms. In the event of any discrepancy or inconsistency
                      between these terms and disclosures or other statements
                      contained in any Demo-related materials, the terms and
                      conditions of these terms posted on ironcap.ca shall
                      prevail, govern and control.
                      <br />
                      <br />
                    </li>

                    <li>
                      These rules shall be governed by and construed in
                      accordance with the laws of the Province of Ontario and
                      the federal laws of Canada applicable therein. The parties
                      hereby attorn to the exclusive jurisdiction of the
                      Superior Court of the Province of Ontario to adjudicate
                      any and all disputes regarding the validity,
                      interpretation and/or enforcement of this Agreement. The
                      parties have agreed that this agreement and all documents
                      related hereto be drafted in English. Les parties ont
                      requis que la présente convention soit redigée en anglais.
                      <br />
                      <br />
                    </li>
                  </ol>
                </li>
              </ol>

              {/* end */}
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="offset-md-2 col-md-10">
            <div className="form-check form-check-inline">
              <p>
                <input
                  checked={this.state.activeCheckBox}
                  onClick={this.handleCheckboxClick}
                  disabled={
                    this.state.enableCheckbox === false ? "disabled" : null
                  }
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option1"
                />

                <label className="form-check-label" htmlFor="inlineCheckbox1">
                  I agree{" "}
                </label>
                <br />
                <i className="text-muted">
                  (You need to read to the bottom before you can click the &lt;I
                  agree&gt; box.)
                </i>
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="offset-md-2 col-md-1">
            <button
              className="btn btn-agreement-accept"
              disabled={this.state.activeCheckBox === false ? "disabled" : null}
              onClick={this.props.handleAcceptAgreement}
            >
              Accept{" "}
            </button>
          </div>

          <div className="col-md-2">
            <button
              className="btn btn-agreement-cancel"
              onClick={this.props.handleDeclineAgreement}
            >
              Decline{" "}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default AgreementBountyContest
