import React, { Component } from "react"
import * as styles from "./registrationBountyContest.module.scss"
import Popup from "reactjs-popup"
import bwlogo from "../../../../assets/IronCAP logo (30 x 32).png"
import "../../../static.css"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { TM } from "../../common/typography/trademark"

class RegistrationBountyContest extends Component {
  constructor(props) {
    super()
  }

  render() {
    return (
      <Popup
        className="registration"
        open={this.props.open}
        closeOnDocumentClick
        onClose={this.props.closeModal}
      >
        {close => (
          <div>
            <div className={styles.modal}>
              <a className={styles.close} onClick={this.props.closeModal}>
                &times;
              </a>
              <div className={styles.header}>
                <div className={styles.label}>Complete your information</div>
                <div className={styles.description}>
                  Please review and provide any missing information to finish
                  signing up
                </div>
              </div>

              <div className={styles.content}>
                <div className={styles.gap}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className={styles.inputuserwrapper}>
                        <input
                          id="firstname"
                          readOnly="readOnly"
                          ref="firstname"
                          type="text"
                          className={styles.formcontrol}
                          placeholder="First Name"
                          value={this.props.firstname}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.gap}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className={styles.inputuserwrapper}>
                        <input
                          id="lastname"
                          readOnly="readOnly"
                          ref="lastname"
                          type="text"
                          className={styles.formcontrol}
                          placeholder="Last Name"
                          value={this.props.lastname}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.gap}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className={styles.inputemailwrapper}>
                        <input
                          id="email"
                          readOnly="readOnly"
                          type="email"
                          ref="email"
                          value={this.props.email}
                          className={styles.formcontrol}
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="text-center">
                      <p>
                        This info came from{" "}
                        {this.props.logintype === "f" ? "Facebook" : "Google"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    We'll send you marketing promotions, special offers,
                    inspiration, and policy updates via email.
                  </div>
                </div>
              </div>

              <div className={styles.actions}>
                <button
                  className="form-control btn btn-danger btn-registration"
                  onClick={() => {
                    this.props.closeModalWithAccept()
                  }}
                >
                  Finish Signing Up
                </button>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="optoutMarketing"
                      onClick={this.props.handleOptOutMarketing}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="optoutMarketing"
                    >
                      I don't want to receive marketing messages from IronCAP
                      <TM />. I can also opt out of receiving these at any time
                      in my account settings or via the link in the message.
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.modalfooter}>
              <img src={bwlogo} className={styles.bwlogo} />
              Copyright © 2018-{new Date().getFullYear()}{" "}
              <OutboundLink
                href="https://01com.com"
                target="_blank"
                className={styles.outboundLink}
              >
                01 Communique Laboratory Inc.
              </OutboundLink>
              . All Rights Reserved
            </div>
          </div>
        )}
      </Popup>
    )
  }
}

export default RegistrationBountyContest
