import React from "react"

import * as styles from "./aboutBountyContest.module.scss"
import "../../../static.css"
import { TM } from "../../common/typography/trademark"

const AboutBountyContest = () => (
  <div className={styles.verticalApplicationsSection}>
    <h2>
      IronCAP
      <TM /> Live Demo
    </h2>

    <div className="row">
      <div className="col-md-12">
        IronCAP
        <TM /> is our latest innovation for the post-quantum cybersecurity. This
        patent-protected, post-quantum cryptographic system is based on the
        Goppa Code-based cryptographic technology. It has embedded our
        proprietary subclass of (L, G) making it not only more secured but also
        has faster cryptographic operations (key generation, encryption,
        decryption) than the traditional Goppa Code-based technology (McEliece).
        We are offering a live demonstration for the general public to try and
        experience the strength of IronCAP
        <TM /> post-quantum encryption easily.
      </div>
    </div>

    <div className="row top-buffer ">
      <div className="col-md-6">
        <div className={styles.aboutgroup}>
          <h3>Eligibility</h3>
          <ul>
            <li>Anyone with a Facebook or Google account </li>
            <li>Any country </li>
            <li>
              You need to agree to the official Rules, Terms & Conditions as set
              forth in the signup process.
            </li>
          </ul>
        </div>

        <div className={styles.aboutgroup}>
          <h3>How it works</h3>
          <ul>
            <li>Register an account</li>
            <li>Login to the account</li>
            <li>
              Try the IronCAP
              <TM /> encryption/decryption
            </li>
          </ul>
        </div>

        <div className={styles.aboutgroup}>
          <h3>Notes</h3>
          <ul>
            <li>
              Each demo account has a different set of <TM /> keys{" "}
            </li>
            <li>
              Files you have encrypted can ONLY be decrypted in your account{" "}
            </li>
          </ul>
        </div>
      </div>

      <div className="col-md-6">
        <div className={styles.aboutgroup}>
          <h3>
            Try IronCAP
            <TM /> encryption
          </h3>
          <ul>
            <li>Upload any file (Max: 2MB) to be encrypted</li>
            <li>Click "Encrypt" to encrypt the uploaded file</li>
            <li>The encrypted file will be downloaded</li>
          </ul>
        </div>

        <div className={styles.aboutgroup}>
          <h3>
            Try IronCAP
            <TM /> decryption
          </h3>
          <ul>
            <li>
              Upload any file previously encrypted by IronCAP
              <TM />
            </li>
            <li> Click "Decrypt" to decrypt the uploaded file </li>
            <li>
              The decrypted file will be downloaded
              <br />
              (files not encrypted by IronCAP
              <TM /> will fail to decrypt)
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default AboutBountyContest
