import React, { Component } from "react"
import Layout from "../components/layout"

import AboutBountyContest from "../components/sections/demo/aboutBountyContest"
import RegistrationBountyContest from "../components/sections/demo/registrationBountyContest"
import WelcomeBountyContest from "../components/sections/demo/welcomeBountyContest"
import AgreementBountyContest from "../components/sections/demo/agreementBountyContest"
import DeclineBountyContest from "../components/sections/demo/declineBountyContest"
import ToolBountyContest from "../components/sections/demo/toolBountyContest"
// import SubmissionBountyContest from "../components/sections/demo/submissionBountyContest"
import DisclaimerBountyContest from "../components/sections/demo/disclaimerBountyContest"

import axios from "axios"
import FileDownload from "js-file-download"

import CTABanner from "../components/common/ctaBanner/ctaBanner"
import * as styles from "./demo.module.scss"
import HowItWorks from "../components/sections/howItWorks/howItWorks"
import "../static.css"
import { LearnMoreTagline } from "../components/common/typography/banner-tagline"
import { TM } from "../components/common/typography/trademark"

class IronCAPBountyContest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMsg: null,
      selectedEncryptFile: null,
      selectedDecryptFile: null,
      agreementID: "",
      userID: "",
      mode: "welcome",
      enableAgreement: false,
      open: false,
      email: "",
      firstname: "",
      lastname: "",
      logintype: "",
      token: "",
      photo: "",
      submissionlastDt: null,
      submissionTotal: null,
      answerSubmitted: false,
      submissionText: null,
      errorTool: null,
      errorToolDecryption: null,
      errorToolEncryption: null,
      errorSubmission: null,
      resultMatched: null,
      subscription: "y",
      isToolDisplayFile: 0,
      enableToolBtnEncryption: "disabled",
      enableToolBtnDecryption: "disabled",
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.closeModalWithAccept = this.closeModalWithAccept.bind(this)
    this.handleAcceptAgreement = this.handleAcceptAgreement.bind(this)
    this.handleDeclineAgreement = this.handleDeclineAgreement.bind(this)
    this.handleReturnToWelcome = this.handleReturnToWelcome.bind(this)
    this.handleReturnToAgreement = this.handleReturnToAgreement.bind(this)
    this.handleEncryptionBinary = this.handleEncryptionBinary.bind(this)
    this.handleDecryptionBinary = this.handleDecryptionBinary.bind(this)
    this.handleSubmission = this.handleSubmission.bind(this)
    this.closeModalWithSubmission = this.closeModalWithSubmission.bind(this)
    this.onChangeUploadFileEncryptHandler = this.onChangeUploadFileEncryptHandler.bind(
      this
    )
    this.onChangeUploadFileDecryptHandler = this.onChangeUploadFileDecryptHandler.bind(
      this
    )

    this.handleLogout = this.handleLogout.bind(this)
    this.handleLastSubmissionData = this.handleLastSubmissionData.bind(this)
    this.handleEnableSubmissionButton = this.handleEnableSubmissionButton.bind(
      this
    )
    this.handleOptOutMarketing = this.handleOptOutMarketing.bind(this)
    this.handleToolDisplayText = this.handleToolDisplayText.bind(this)
    this.handleToolDisplayFile = this.handleToolDisplayFile.bind(this)

    this.handleValidateText = this.handleValidateText.bind(this)
  }

  handleOptOutMarketing(event) {
    let subscript = event.target.checked ? "n" : "y"
    this.setState({ subscription: subscript })
  }

  handleToolDisplayText(e) {
    if (this.state.isToolDisplayFile == 1) {
      this.setState({
        isToolDisplayFile: 0,
        selectedEncryptFile: null,
        errorToolEncryption: null,
        enableToolBtnEncryption: "disabled",
      })
    }
  }

  handleToolDisplayFile(e) {
    if (this.state.isToolDisplayFile == 0) {
      this.setState({
        isToolDisplayFile: 1,
        errorToolEncryption: null,
        enableToolBtnEncryption: "disabled",
      })
    }
  }

  handleLogout() {
    this.setState({ mode: "welcome" })
  }

  openModal() {
    this.setState({ open: true })
  }

  closeModal() {
    this.setState({ answerSubmitted: false, open: false })
  }

  closeModalWithAccept() {
    var data = {
      id: this.state.userID,
      subscription: this.state.subscription,
      logintype: this.state.logintype,
      token: this.state.token,
    }
    let url = this.getApiURL("subscription")
    let self = this

    axios
      .post(url, data)
      .then(function(response) {
        // let results = response.data
        self.setState({ open: false, mode: "agreement" })
      })
      .catch(function(error) {
        if (error && error.response && error.response.status === 440) {
          self.setState({ mode: "welcome" })
        }

        if (error && error.response && error.response.status === 400) {
          self.setState({ errorMsg: error.response.data.message })
        }
      })
  }

  getExtension(filename) {
    var i = filename.lastIndexOf(".")
    return i < 0 ? "" : filename.substr(i).toLowerCase()
  }

  handleValidateText(e) {
    this.setState({ enableToolBtnEncryption: null })
  }

  onChangeUploadFileEncryptHandler(e) {
    if (e.target.files.length > 0) {
      let size = e.target.files[0].size

      if (size > 2097152) {
        this.setState({
          errorToolEncryption: "File size must be smaller than 2MB.",
        })
        return
      }

      this.setState({
        selectedEncryptFile: e.target.files[0],
        enableToolBtnEncryption: null,
        errorToolEncryption: null,
      })
    } else {
      this.setState({
        selectedEncryptFile: null,
        enableToolBtnEncryption: "disabled",
        errorToolEncryption: null,
      })
    }
  }

  onChangeUploadFileDecryptHandler(e) {
    this.setState({
      selectedDecryptFile: e.target.files[0],
      enableToolBtnDecryption: null,
      errorToolDecryption: null,
    })
  }

  handleEnableSubmissionButton(event) {
    this.setState({ submissionText: event.target.value.trim() })
  }

  closeModalWithSubmission() {
    const data = new FormData()
    let txtIn = document.getElementById("description")
    data.append("id", this.state.userID)
    data.append("agreement_id", this.state.agreementID)
    data.append("logintype", this.state.logintype)
    data.append("token", this.state.token)
    data.append("description", txtIn.value)
    data.append("file", this.state.selectedEncryptFile)
    var self = this
    self.setState({ errorSubmission: null })

    let url = this.getApiURL("submission")

    axios
      .post(url, data, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then(function(response) {
        self.handleLastSubmissionData()

        self.setState({
          answerSubmitted: true,
          resultMatched: response.data.match,
        })
      })
      .catch(function(error) {
        if (error && error.response && error.response.status === 400) {
          self.setState({ errorSubmission: error.response.data.message })
        }

        if (error && error.response && error.response.status === 440) {
          self.setState({ mode: "welcome" })
        }
      })
  }

  handleEncryptionBinary(e) {
    e.preventDefault()

    let txtIn = document.getElementById("textIn")
    var buffer = null
    var input = null

    if (txtIn) {
      buffer = Buffer.from(txtIn.value, "utf8")
      input = buffer.toString("hex")
      var len = Buffer.byteLength(buffer)
      if (len > 2097152) {
        this.setState({ errorToolEncryption: "Max 2MB is allowed" })
        return
      }
    }

    const data = new FormData()
    //  let txtIn = document.getElementById("description");
    data.append("id", this.state.userID)
    data.append("agreement_id", this.state.agreementID)
    data.append("logintype", this.state.logintype)
    data.append("token", this.state.token)

    data.append("convert", "e")
    data.append("out", "binary")

    if (this.state.isToolDisplayFile) {
      data.append("file", this.state.selectedEncryptFile)
    } else {
      data.append("input", input)
    }

    data.append("isFileUpload", this.state.isToolDisplayFile)

    // var data = {id:this.state.userID,agreement_id:this.state.agreementID, logintype:this.state.logintype,token:this.state.token,input:input,"convert":"e",out:"binary" };;

    let url = this.getApiURL("tool")
    let self = this
    let fileName =
      this.state.selectedEncryptFile == null
        ? "encrypted.bin"
        : this.state.selectedEncryptFile.name + ".bin"

    axios
      .post(url, data, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then(function(response) {
        let bufferOut = Buffer.from(response.data.data)
        FileDownload(bufferOut, fileName)
      })
      .catch(function(error) {
        if (error && error.response && error.response.status === 400) {
          self.setState({ errorToolEncryption: error.response.data.message })
        }

        if (error && error.response && error.response.status === 440) {
          self.setState({ mode: "welcome" })
        }
      })
  }

  handleDecryptionBinary(e) {
    e.preventDefault()

    const data = new FormData()
    data.append("id", this.state.userID)
    data.append("agreement_id", this.state.agreementID)
    data.append("logintype", this.state.logintype)
    data.append("token", this.state.token)

    data.append("convert", "d")
    data.append("out", "binary")
    data.append("file", this.state.selectedDecryptFile)
    data.append("isFileUpload", this.state.isToolDisplayFile)

    //  var data = {id:this.state.userID,agreement_id:this.state.agreementID, logintype:this.state.logintype,token:this.state.token,input:input,"convert":"d",out:"binary" };

    let url = this.getApiURL("tool")
    let self = this

    axios
      .post(url, data, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then(function(response) {
        let bufferOut = Buffer.from(response.data.data)
        let filename = response.data.fileUploadedName
        FileDownload(bufferOut, filename)
      })
      .catch(function(error) {
        if (error && error.response && error.response.status === 400) {
          self.setState({ errorToolDecryption: error.response.data.message })
        }

        if (error && error.response && error.response.status === 440) {
          self.setState({ mode: "welcome" })
        }
      })
  }

  handleAcceptAgreement() {
    var data = {
      id: this.state.userID,
      accepted: "y",
      logintype: this.state.logintype,
      token: this.state.token,
    }
    let url = this.getApiURL("agreement")
    let self = this

    axios
      .post(url, data)
      .then(function(response) {
        let results = response.data
        self.setState({
          mode: "accept",
          agreementID: results.id,
          enableToolBtnEncryption: "disabled",
          enableToolBtnDecryption: "disabled",
        })
      })
      .catch(function(error) {
        if (error && error.response && error.response.status === 440) {
          self.setState({ mode: "welcome" })
        }

        if (error && error.response && error.response.status === 400) {
          self.setState({ errorMsg: error.response.data.message })
        }
      })
  }

  handleDeclineAgreement() {
    var data = {
      id: this.state.userID,
      accepted: "n",
      logintype: this.state.logintype,
      token: this.state.token,
    }

    let url = this.getApiURL("agreement")
    let self = this

    axios
      .post(url, data)
      .then(function(response) {
        let results = response.data
        self.setState({ mode: "decline", agreement_id: results.id })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  handleReturnToAgreement() {
    this.setState({ mode: "agreement" })
  }

  handleReturnToWelcome() {
    this.setState({ mode: "welcome" })
  }

  // this is for popup , used in two places.
  handleSubmission() {
    this.setState({
      selectedEncryptFile: null,
      resultMatched: null,
      errorSubmission: null,
      open: true,
      submissionText: null,
    })
  }

  handleLastSubmissionData() {
    var data = {
      id: this.state.userID,
      agreement_id: this.state.agreementID,
      logintype: this.state.logintype,
      token: this.state.token,
    }
    let url = this.getApiURL("submission/list")
    let self = this

    axios
      .post(url, data)
      .then(function(response) {
        let results = response.data
        self.setState({
          submissionlastDt: results.lastrec,
          submissionTotal: results.total,
        })
      })
      .catch(function(error) {
        if (error.response.status === 440) {
          self.setState({ mode: "welcome" })
        }
      })
  }

  componentClicked = () => {}

  showWelcome() {
    const windowGlobal = typeof window !== "undefined" && window
    if (windowGlobal) {
      windowGlobal.scrollTo(0, 0)
    }
    if (this.state.mode === "welcome") {
      return (
        <React.Fragment>
          <WelcomeBountyContest
            openModal={this.openModal}
            responseGoogle={this.responseGoogle}
            responseFacebook={this.responseFacebook}
            responseGoogleLogin={this.responseGoogleLogin}
            responseFacebookLogin={this.responseFacebookLogin}
            errorMsg={this.state.errorMsg}
          />

          <RegistrationBountyContest
            open={this.state.open}
            email={this.state.email}
            firstname={this.state.firstname}
            lastname={this.state.lastname}
            closeModal={this.closeModal}
            closeModalWithAccept={this.closeModalWithAccept}
            mode={this.state.mode}
            handleOptOutMarketing={this.handleOptOutMarketing}
            logintype={this.state.logintype}
          />
        </React.Fragment>
      )
    }

    if (this.state.mode === "agreement") {
      return (
        <AgreementBountyContest
          mode={this.state.mode}
          handleAcceptAgreement={this.handleAcceptAgreement}
          handleDeclineAgreement={this.handleDeclineAgreement}
        />
      )
    }

    if (this.state.mode === "decline") {
      return (
        <DeclineBountyContest
          mode={this.state.mode}
          handleReturnToAgreement={this.handleReturnToAgreement}
          handleReturnToWelcome={this.handleReturnToWelcome}
        />
      )
    }

    if (this.state.mode === "accept") {
      return (
        <React.Fragment>
          <ToolBountyContest
            mode={this.state.mode}
            handleEncryptionBinary={this.handleEncryptionBinary}
            handleDecryptionBinary={this.handleDecryptionBinary}
            handleSubmission={this.handleSubmission}
            email={this.state.email}
            logintype={this.state.logintype}
            photo={this.state.photo}
            handleLogout={this.handleLogout}
            submissionlastDt={this.state.submissionlastDt}
            submissionTotal={this.state.submissionTotal}
            handleLastSubmissionData={this.handleLastSubmissionData}
            errorToolEncryption={this.state.errorToolEncryption}
            errorToolDecryption={this.state.errorToolDecryption}
            handleToolDisplayText={this.handleToolDisplayText}
            handleToolDisplayFile={this.handleToolDisplayFile}
            isToolDisplayFile={this.state.isToolDisplayFile}
            onChangeUploadFileEncryptHandler={
              this.onChangeUploadFileEncryptHandler
            }
            onChangeUploadFileDecryptHandler={
              this.onChangeUploadFileDecryptHandler
            }
            handleValidateText={this.handleValidateText}
            enableToolBtnEncryption={this.state.enableToolBtnEncryption}
            enableToolBtnDecryption={this.state.enableToolBtnDecryption}
          />
        </React.Fragment>
      )
    }
  }

  getApiURL(param) {
    return `${process.env.DEMO_SERVER_API_URL}/${param}`
  }

  register(type) {
    var data = {
      email: this.state.email,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      logintype: this.state.logintype,
      token: this.state.token,
    }

    let url = this.getApiURL("register")
    var self = this

    axios
      .post(url, data)
      .then(function(response) {
        let results = response.data

        if (results.agreement_id && results.agreement_id > 0) {
          self.setState({
            userID: results.id,
            agreementID: results.agreement_id,
            mode: "accept",
            errorMsg: null,
            errorToolDecryption: null,
            errorToolEncryption: null,
            enableToolBtnEncryption: "disabled",
            enableToolBtnDecryption: "disabled",
            selectedEncryptFile: null,
            selectedDecryptFile: null,
            isToolDisplayFile: 0,
          })
        } else {
          if (type === "reg") {
            self.setState({
              userID: results.id,
              agreementID: results.agreement_id,
              open: true,
              errorMsg: null,
            })
          } else {
            self.setState({
              errorMsg: (
                <>
                  You must first register to the IronCAP
                  <TM /> Live Demo to use this option
                </>
              ),
            })
          }
        }
      })
      .catch(function(error) {
        if (error && error.response && error.response.status === 440) {
          self.setState({ mode: "welcome" })
        }

        if (error && error.response && error.response.status === 400) {
          self.setState({ errorMsg: error.response.data.message })
        }
      })
  }

  declineAgreement() {
    var data = {
      id: this.user_id,
      accepted: "n",
      logintype: this.state.logintype,
      token: this.state.token,
    }

    let url = this.getApiURL("agreement")

    axios
      .post(url, data)
      .then(function(response) {
        //  console.log(response);
        //alert("Saved successfully");
      })
      .catch(function(error) {
        // console.log(error);
      })
  }

  responseFacebook = response => {
    // console.log(response);
    if (response.email) {
      this.setState({
        photo: response.picture.data.url,
        email: response.email,
        firstname: response.first_name,
        lastname: response.last_name,
        logintype: "f",
        token: response.accessToken,
      })
      this.register("reg")
    }
  }

  responseFacebookLogin = response => {
    //console.log(response);
    if (response.email) {
      this.setState({
        photo: response.picture.data.url,
        email: response.email,
        firstname: response.first_name,
        lastname: response.last_name,
        logintype: "f",
        token: response.accessToken,
      })
      this.register("login")
    }
  }

  responseGoogle = response => {
    //console.log(response);
    if (response.profileObj) {
      this.setState({
        photo: response.profileObj.imageUrl,
        email: response.profileObj.email,
        firstname: response.profileObj.givenName,
        lastname: response.profileObj.familyName,
        logintype: "g",
        token: response.accessToken,
      })
      this.register("reg")
    }
  }

  responseGoogleLogin = response => {
    //  console.log(response);
    if (response.profileObj) {
      this.setState({
        photo: response.profileObj.imageUrl,
        email: response.profileObj.email,
        firstname: response.profileObj.givenName,
        lastname: response.profileObj.familyName,
        logintype: "g",
        token: response.accessToken,
      })
      this.register("login")
    }
  }

  render() {
    return (
      <Layout>
        <input type="hidden" name="acctType" value={this.state.logintype} />
        <input type="hidden" name="token" value={this.state.token} />

        <div id={styles.carouselContainer}>{this.showWelcome()}</div>

        <AboutBountyContest />

        <HowItWorks />

        <DisclaimerBountyContest />

        <CTABanner
          tagline={LearnMoreTagline}
          CTA="video"
          CTADetail="how video"
        />
      </Layout>
    )
  }
}

export default IronCAPBountyContest
