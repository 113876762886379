import React, { Component } from "react"
import * as styles from "./welcomeBountyContest.module.scss"
import ProgressiveImage from "react-progressive-image-loading"
import banner3bg from "../../../../assets/Home-BountyContest.png"
import banner3bgmin from "../../../../assets/Home-BountyContest.png"
import FacebookLogin from "react-facebook-login"
import GoogleLogin from "../react-google-login/google-login.js"

class WelcomeBountyContest extends Component {
  render() {
    return (
      <ProgressiveImage
        preview={banner3bgmin}
        src={banner3bg}
        transitionTime={500}
        transitionFunction="ease"
        render={(src, style) => (
          <div
            className={styles.carouselGraphic3}
            style={Object.assign(style, { backgroundImage: `url(${src})` })}
          >
            <div className={styles.carouselText}></div>

            <section className={styles.carouselImg}>
              <div className={styles.registerHeading}>Registration</div>

              <FacebookLogin
                appId={process.env.FACEBOOK_API_ID}
                autoLoad={false}
                textButton=" Continue with Facebook"
                fields="first_name,last_name,email,name,picture"
                scope="public_profile, email"
                onClick={this.componentClicked}
                callback={this.props.responseFacebook}
                size="small"
                icon="fa fa-facebook-official"
                cssClass={styles.customfacebook}
              />

            
              <GoogleLogin
                clientId={process.env.GOOGLE_API_ID}
                buttonText="Continue with Google"
                onSuccess={this.props.responseGoogle}
                onFailure={this.props.responseGoogle}
                cookiePolicy={"single_host_origin"}
                className={styles.customgoogle}
              />

              <div className={styles.loginHeading}>
                <span>Already Registered ?</span>
              </div>

              <FacebookLogin
                appId="1597333030401506"
                autoLoad={false}
                textButton=" Login with Facebook"
                fields="first_name,last_name,email,name,picture"
                scope="public_profile, email"
                onClick={this.componentClicked}
                callback={this.props.responseFacebookLogin}
                size="small"
                icon="fa fa-facebook-official"
                cssClass={styles.customfacebook}
              />

              <GoogleLogin
                clientId={process.env.GOOGLE_API_ID}
                buttonText="Continue with Google"
                onSuccess={this.props.responseGoogle}
                onFailure={this.props.responseGoogle}
                cookiePolicy={"single_host_origin"}
                className={styles.customgoogle}
              />

             

              <div className={styles.errorMsg}>{this.props.errorMsg}</div>
              <div className={styles.loginHeading}></div>
            </section>
          </div>
        )}
      />
    )
  }
}

export default WelcomeBountyContest
